import * as React from "react";
import {
  Box,
  Text,
  Heading,
  Center,
  Button,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Fonts } from "../utils/fonts";
import Layout from "../components/layout";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { useDropzone } from "react-dropzone";
import Head from "../components/head";

const SubmitDesignPage = () => {
  const [file, setFile] = React.useState(null);
  const [sendResult, setSendResult] = React.useState(null);
  const toast = useToast();

  const onDrop = React.useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  return (
    <Layout>
      <Head
        title="Create 15 - Submit a Design"
        description="Laser cutting and engraving in Johannesburg. Various materials like MDF, wood, bamboo and perspex."
      />

      <Center h="60px" color="black">
        <Heading as="h1">Submit a Design</Heading>
      </Center>
      <Box textAlign="center">
        <Text fontSize="l">
          Doing a custom engraving or product requires design time and tests.
        </Text>
        <Text fontSize="l">
          A custom design will start at R400 for the design and setup. Then a
          per product cost can be worked out depending on the number of products
          and type of material.
        </Text>
        <Text fontSize="l">
          Submit your design and details below. We'll email you a quote or more
          questions needed to make your design come to life.
        </Text>
      </Box>
      <Box margin={4} display="flex" justifyContent="center">
        <Box w={["sm", "md", "md"]}>
          <Formik
            initialValues={{
              name: "",
              email: "",
              material: "",
              notes: "",
              quantity: 1,
            }}
            validationSchema={() =>
              object().shape({
                name: string()
                  .required("Name is required!")
                  .min(2, "Name is too short!")
                  .max(500, "Name is too long!"),
                email: string()
                  .email("E-mail is not valid!")
                  .required("E-mail is required!"),
                material: string().required("Please select a material!"),
                notes: string(),
              })
            }
            onSubmit={async (values, { setSubmitting }) => {
              const data = { "form-name": "design", file, ...values };
              setSubmitting(true);
              try {
                const response = await fetch("/", {
                  method: "POST",
                  // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
                  body: encode(data),
                });
                if (response.ok) {
                  setSubmitting(false);
                  setSendResult({ success: "ok" });

                  toast({
                    title: "Sent",
                    description:
                      "Thanks!, your design is submitted we'll contact you with the next steps.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                } else {
                  setSubmitting(false);
                  console.error("Send Error:");
                  setSendResult({ error: true });
                  toast({
                    title: "Error",
                    description:
                      "Sorry we cannot submit your design there is a problem on our side. Please email us directly.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                }
              } catch (error) {
                setSubmitting(false);
                console.error("Send Error:", error);
                setSendResult({ error: true });
                toast({
                  title: "Error",
                  description:
                    "Sorry we cannot submit your design there is a problem on our side. Please email us directly.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            }}
          >
            {(props) => (
              <Box>
                <Form
                  name="design"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="design" />

                  <Field name="bot-field" type="text">
                    {({ field, meta }) => (
                      <input
                        type="text"
                        name="bot-field"
                        style={{ display: "none" }}
                        tabIndex="-1"
                        autoComplete="off"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="name" type="text">
                    {({ field, meta }) => (
                      <Box mb="2">
                        <Text as="label" htmlFor={field.name}>
                          Name
                        </Text>
                        <Input
                          required
                          id={field.name}
                          isInvalid={meta.touched && meta.error}
                          errorBorderColor="red.500"
                          placeholder="John"
                          {...field}
                        />
                        <Text fontSize="sm" color="red.500" as="small">
                          {meta.error}
                        </Text>
                      </Box>
                    )}
                  </Field>

                  <Field name="email" type="text">
                    {({ field, meta }) => (
                      <Box mb="2">
                        <Text as="label" htmlFor={field.name}>
                          Email
                        </Text>
                        <Input
                          required
                          id={field.name}
                          isInvalid={meta.touched && meta.error}
                          errorBorderColor="red.500"
                          placeholder="john@email.com"
                          {...field}
                        />
                        <Text fontSize="sm" color="red.500" as="small">
                          {meta.error}
                        </Text>
                      </Box>
                    )}
                  </Field>

                  <Field name="material" type="text">
                    {({ field, meta }) => (
                      <Box mb="2">
                        <Text as="label" htmlFor={field.name}>
                          Material
                        </Text>
                        <Select
                          placeholder="Select material"
                          {...field}
                          id={field.name}
                        >
                          <option value="mdf">MDF (compressed wood)</option>
                          <option value="bamboo">Bamboo</option>
                          <option value="perspex">Perspex (acrylic)</option>
                          <option value="leather">Leather</option>
                          <option value="other">Other</option>
                        </Select>
                        <Text fontSize="sm" color="red.500" as="small">
                          {meta.error}
                        </Text>
                      </Box>
                    )}
                  </Field>

                  <Field name="quantity" type="number">
                    {({ field, meta }) => (
                      <Box mb="2">
                        <Text as="label" htmlFor={field.name}>
                          How many do you expect to make?
                        </Text>
                        <NumberInput
                          id={field.name}
                          onChange={(e) => props.setFieldValue(field.name, e)}
                          required
                          defaultValue={1}
                          min={1}
                          max={200}
                          // {...field}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                    )}
                  </Field>

                  <Field name="notes" type="text">
                    {({ field, meta }) => (
                      <Box mb="2">
                        <Text as="label" htmlFor={field.name}>
                          Notes
                        </Text>
                        <Textarea
                          id={field.name}
                          isInvalid={meta.touched && meta.error}
                          errorBorderColor="red.500"
                          placeholder="add any details about the design like the size, what it is for and when you need it by"
                          size="sm"
                          {...field}
                        />
                        <Text fontSize="sm" color="red.500" as="small">
                          {meta.error}
                        </Text>
                      </Box>
                    )}
                  </Field>

                  <Box mb="2">
                    <Text as="label">Design File</Text>

                    
                      <Box
                        borderRadius="md"
                        borderWidth="1px"
                        borderStyle="dashed"
                        borderColor="grey.300"
                        p="2"
                        mt="2"
                        height="80px"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} name="file" />
                        {isDragActive ? (
                          <p>Drop the file here ...</p>
                        ) : (
                          <p>
                            Drag 'n' drop file here, or click to select file
                          </p>
                        )}
                      </Box>
                    {file && <Box mt={2}>File - {file.name}</Box>}

                  </Box>

                  <Box>
                    <Button
                      colorScheme="brandOrange"
                      bgColor="brandOrange.300"
                      type="submit"
                      disabled={props.isSubmitting || !file}
                      isLoading={props.isSubmitting}
                      loadingText="Sending"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Layout>
  );
};

export default SubmitDesignPage;
